import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

	
	gsap.from(".featured-service h3", {
		scrollTrigger: {
			trigger: ".featured-service",
			start: "20% 80%",
		},
		"opacity": "0", y: 25, duration: 1.5}		
	);

	gsap.from(".featured-service p", {
		scrollTrigger: {
			trigger: ".featured-service",
			start: "20% 80%",
		},
		"opacity": "0", y: 25, duration: 1.5}		
	);

	gsap.to(".elevator-pitch strong", {
		scrollTrigger: {
			trigger: ".elevator-pitch",
			start: "20% 80%",
		},
		"color": "#0061A8", duration: 2.5}
	);

		
});